import type {Group, Texture} from 'three'
import {TextureLoader} from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'

interface Gltf {
  src: string
  scene?: null | Group
}

interface Image {
  src: string
  texture?: null | Texture
  flipY: boolean
}

const path = '/images/modules/site/home24/webgl-assets'

export default class Assets {
  gltfs: {[key: string]: Gltf} = {
    cat: {
      src: `${path}/mascot/cat/cat.glb`,
      scene: null,
    },
    duck: {
      src: `${path}/mascot/duck/duck.glb`,
      scene: null,
    },
    copilot: {
      src: `${path}/mascot/copilot/copilot.glb`,
      scene: null,
    },
  }

  images: {[key: string]: Image} = {
    // matcap
    matcap_mascot: {
      src: `${path}/matcaps/mascot.jpg`,
      texture: null,
      flipY: false,
    },
    matcap_cateye: {
      src: `${path}/matcaps/cat_eye.jpg`,
      texture: null,
      flipY: false,
    },
    star: {
      src: `${path}/textures/star.jpg`,
      texture: null,
      flipY: false,
    },
    // cat
    cat_nose_ao: {
      src: `${path}/mascot/cat/textures/nose_sss.jpg`,
      texture: null,
      flipY: false,
    },
    cat_head_ao: {
      src: `${path}/mascot/cat/textures/head_sss.jpg`,
      texture: null,
      flipY: false,
    },
    cat_face_ao: {
      src: `${path}/mascot/cat/textures/face_sss.jpg`,
      texture: null,
      flipY: false,
    },
    cat_eye_ao: {
      src: `${path}/mascot/cat/textures/eye_sss.jpg`,
      texture: null,
      flipY: false,
    },
    cat_eyeball_ao: {
      src: `${path}/mascot/cat/textures/eyeball_sss.jpg`,
      texture: null,
      flipY: false,
    },

    cat_eye_color: {
      src: `${path}/mascot/cat/textures/eye_color.jpg`,
      texture: null,
      flipY: false,
    },

    // duck
    duck_body_ao: {
      src: `${path}/mascot/duck/textures/body_sss.jpg`,
      texture: null,
      flipY: false,
    },
    duck_beak_ao: {
      src: `${path}/mascot/duck/textures/beak_sss.jpg`,
      texture: null,
      flipY: false,
    },
    duck_eyes_ao: {
      src: `${path}/mascot/duck/textures/eyes_sss.jpg`,
      texture: null,
      flipY: false,
    },
    duck_eyeballs_ao: {
      src: `${path}/mascot/duck/textures/eyeballs_sss.jpg`,
      texture: null,
      flipY: false,
    },

    // copilot

    copilot_eyes_ao: {
      src: `${path}/mascot/copilot/textures/eyes_sss.jpg`,
      texture: null,
      flipY: false,
    },
    copilot_face_ao: {
      src: `${path}/mascot/copilot/textures/face_sss.jpg`,
      texture: null,
      flipY: false,
    },
    copilot_glass_ao: {
      src: `${path}/mascot/copilot/textures/glass_sss.jpg`,
      texture: null,
      flipY: false,
    },
    copilot_goggle_ao: {
      src: `${path}/mascot/copilot/textures/goggle_sss.jpg`,
      texture: null,
      flipY: false,
    },
    copilot_head_ao: {
      src: `${path}/mascot/copilot/textures/head_sss.jpg`,
      texture: null,
      flipY: false,
    },
  }
  isLoaded: boolean = false

  constructor() {}

  async load(callback: () => void) {
    const allPromisess = [...this.loadImages(), ...this.loadGltfs()]

    try {
      await Promise.all(allPromisess)

      this.isLoaded = true

      // All assets loaded
      if (callback) callback()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error loading assets', error)
    }
  }

  loadGltfs() {
    const loader = new GLTFLoader()

    const promises = Object.values(this.gltfs).map(
      gltf =>
        new Promise((resolve, reject) => {
          loader.load(
            gltf.src,
            loadedGltf => {
              gltf.scene = loadedGltf.scene
              resolve(loadedGltf.scene)
            },
            undefined,
            error => reject(error),
          )
        }),
    )

    return promises
  }

  loadImages() {
    const loader = new TextureLoader()

    const promises = Object.values(this.images).map(
      image =>
        new Promise((resolve, reject) => {
          loader.load(
            image.src,
            texture => {
              image.texture = texture
              texture.flipY = image.flipY
              resolve(texture)
            },
            undefined,
            error => reject(error),
          )
        }),
    )

    return promises
  }
}
