import {Color, Vector3, Euler, Vector2} from 'three'
import type {LightData, GroupData, TextureData} from './mascot-type'

const light_data: LightData = {
  color1: new Color(0x7a4ced),
  color2: new Color(0xce5fe8),
  position: new Vector3(-1, 0, 2.0),
  position2: new Vector3(1.0, -1, 1.0),
}

const group_data: GroupData = {
  position: new Vector3(-0.5, -0.8, 0),
  scale: new Vector3(1.3, 1.3, 1.3),
  rotation: new Euler(0, 0.8, 0.4),
  order: 'ZYX',
  mascot: {
    position: new Vector3(0, 0, 0),
    scale: new Vector3(1, 1, 1),
    rotation: new Euler(-Math.PI * 0.4, 0, Math.PI * 0.5),
  },
}

const bodyColor = new Color(0x9e55f8)

const textures: {[key: string]: TextureData} = {
  eyes: {
    ao: 'copilot_eyes_ao',
    color: null,
    colorVec: new Color(0x3963ef),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  face: {
    ao: 'copilot_face_ao',
    color: null,
    colorVec: new Color(0x05014d),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  glass: {
    ao: 'copilot_glass_ao',
    color: null,
    colorVec: new Color(0x6325b0),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  goggle: {
    ao: 'copilot_goggle_ao',
    color: null,
    colorVec: new Color(0x995be3),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  head: {
    ao: 'copilot_head_ao',
    color: null,
    colorVec: bodyColor,
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
}

export default {
  light_data,
  group_data,
  textures,
}
