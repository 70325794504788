// TODO: Finish implementation

import {useState} from 'react'

interface PlayButtonProps {
  onTimeout: () => void
}

const MAX_COUNTDOWN = 5 // seconds

// eslint-disable-next-line unused-imports/no-unused-vars
const PlayButton: React.FC<PlayButtonProps> = ({onTimeout}) => {
  const [isPaused, setIsPaused] = useState<boolean>(false)
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [countdown, setCountdown] = useState<number>(MAX_COUNTDOWN)

  return (
    <button
      className="lp-PlayButton"
      onClick={() => {
        const newState = !isPaused
        setIsPaused(newState)
      }}
    >
      <div className="lp-PlayButton-icon" />
      <div className="lp-PlayButton-icon lp-PlayButton-icon--pause" />
    </button>
  )
}

export default PlayButton

try{ PlayButton.displayName ||= 'PlayButton' } catch {}