// TODO: Update logos once they are confirmed
// Task: https://github.com/orgs/github/projects/19766/views/2?pane=issue&itemId=81854355

export const LOGOS = [
  {
    image: '/images/modules/site/home24/logos/coca-cola.svg',
    alt: 'Coca Cola’s logo',
    height: '40px',
  },
  {
    image: '/images/modules/site/home24/logos/coyote-logistics.svg',
    alt: 'Coyote Logistics’s logo',
    height: '40px',
  },
  {
    image: '/images/modules/site/home24/logos/duolingo.svg',
    alt: 'Duolingo’s logo',
    height: '40px',
  },
  {
    image: '/images/modules/site/home24/logos/stripe.svg',
    alt: 'Stripe’s logo',
    height: '44px',
  },
  {
    image: '/images/modules/site/home24/logos/shopify.svg',
    alt: 'Shopify’s logo',
    height: '40px',
  },
  {
    image: '/images/modules/site/home24/logos/mercado-libre.svg',
    alt: 'Mercado Libre’s logo',
    height: '40px',
  },
  {
    image: '/images/modules/site/home24/logos/mercedes.svg',
    alt: 'Mercedes Benz’s logo',
    height: '48px',
  },
  {
    image: '/images/modules/site/home24/logos/fidelity.svg',
    alt: 'Fidelity’s logo',
    height: '36px',
  },
  {
    image: '/images/modules/site/home24/logos/dell.svg',
    alt: 'Dell’s logo',
    height: '60px',
  },
  {
    image: '/images/modules/site/home24/logos/lemonade.svg',
    alt: 'Lemonade’s logo',
    height: '36px',
  },
  {
    image: '/images/modules/site/home24/logos/sas.svg',
    alt: 'Scandinavian Airlines’s logo',
    height: '36px',
  },
  {
    image: '/images/modules/site/home24/logos/itau.svg',
    alt: 'Itau’s logo',
    height: '50px',
  },
  {
    image: '/images/modules/site/home24/logos/hover.svg',
    alt: 'Hover’s logo',
    height: '36px',
  },
  {
    image: '/images/modules/site/home24/logos/lyft.svg',
    alt: 'Lyft’s logo',
    height: '64px',
  },
]
