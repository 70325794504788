import {SectionIntro, Stack, Card} from '@primer/react-brand'

import {GraphIcon, RepoIcon} from '@primer/octicons-react'

import {Spacer} from '../components/Spacer'

export default function HeroSection() {
  return (
    <section id="pricing">
      <div className="fp-Section-container">
        <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
          <SectionIntro.Heading size="3">Simple, pay-as-you-go pricing</SectionIntro.Heading>
        </SectionIntro>

        <Spacer size="40px" size1012="80px" />

        <Stack
          className="lp-CardTheme"
          direction={{narrow: 'vertical', regular: 'horizontal', wide: 'horizontal'}}
          gap="spacious"
          padding="none"
        >
          <div className="lp-CardWrapper">
            <Card
              className="lp-Card fp-gradientBorder"
              ctaText="View docs"
              href="https://docs.github.com/billing/managing-billing-for-github-actions/about-billing-for-github-actions"
            >
              <Card.Icon icon={<RepoIcon />} color="green" />

              <Card.Heading size="6">GitHub Actions is free for public repositories</Card.Heading>

              <Card.Description>
                We take pride in our Open Source legacy, and are happy to provide free CI/CD for public repositories.
                Check out the doc to see which runners are included.
              </Card.Description>
            </Card>
          </div>

          <div className="lp-CardWrapper">
            <Card className="lp-Card fp-gradientBorder" ctaText="View pricing" href="https://github.com/pricing">
              <Card.Icon icon={<GraphIcon />} color="green" />

              <Card.Heading size="6">Every GitHub plan includes free usage</Card.Heading>

              <Card.Description>
                Check out plan details to see how many minutes are included and the pricing table below to see which
                runners you can use your free minutes on.
              </Card.Description>
            </Card>
          </div>
        </Stack>
      </div>
    </section>
  )
}

try{ HeroSection.displayName ||= 'HeroSection' } catch {}