import {Color, Vector3, Euler, Vector2} from 'three'
import type {LightData, GroupData, TextureData} from './mascot-type'

const light_data: LightData = {
  color1: new Color(0x9514b3),
  color2: new Color(0xbf7600),
  position: new Vector3(-1, 1, 1.0),
  position2: new Vector3(1, -1, 0.4),
}

const group_data: GroupData = {
  position: new Vector3(0.3, 1.4, 2.0),
  scale: new Vector3(1.1, 1.1, 1.1),
  rotation: new Euler(0, -1.1, 0.3),
  order: 'ZYX',
  mascot: {
    position: new Vector3(0, 0.0, 0.0),
    scale: new Vector3(1, 1, 1),
    rotation: new Euler(-Math.PI * 0.4, 0, Math.PI * 0.5),
  },
}

const textures: {[key: string]: TextureData} = {
  nose: {
    ao: 'cat_nose_ao',
    color: null,
    colorVec: new Color(0x000000),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  eye: {
    ao: 'cat_eye_ao',
    color: 'cat_eye_color',
    colorVec: new Color(0x000000),
    matcap: 'matcap_cateye',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  face: {
    ao: 'cat_face_ao',
    color: null,
    colorVec: new Color(0xff8fd6),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.1, 0.1),
    fogRangeZ: new Vector2(-1.0, -0.5),
  },
  head: {
    ao: 'cat_head_ao',
    color: null,
    colorVec: new Color(0xf763c1),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.1, 0.1),
    fogRangeZ: new Vector2(-1.0, -0.5),
  },

  eyeball: {
    ao: 'cat_eyeball_ao',
    color: null,
    colorVec: new Color(0xffffff),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
}

export default {
  light_data,
  group_data,
  textures,
}
