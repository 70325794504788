// eslint-disable-next-line filenames/match-regex
import {useEffect, useRef, useState} from 'react'

const useIntersectionObserver = (
  element: React.RefObject<HTMLElement>,
  options: IntersectionObserverInit & {isOnce?: boolean} = {},
) => {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false)
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      if (options.isOnce && entry?.isIntersecting) {
        observer.current?.disconnect()
      }

      setIsIntersecting(!!entry?.isIntersecting)
    }, options)

    if (element?.current) {
      observer.current.observe(element.current)
    }

    return () => {
      if (element?.current && observer.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.current.unobserve(element.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isIntersecting,
    observer,
  }
}

export default useIntersectionObserver
