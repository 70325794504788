import {CTABanner, Button, Image, Section} from '@primer/react-brand'

export default function Cta() {
  return (
    <Section id="cta">
      <Image
        src="/images/modules/site/home24/cta.webp"
        alt=""
        width="960"
        style={{display: 'block', marginInline: 'auto'}}
      />

      <CTABanner align="center" hasBackground={false} hasShadow={false}>
        <CTABanner.Heading as="h2">More than 100 million developers call GitHub home</CTABanner.Heading>

        <CTABanner.Description>
          Whether you’re scaling your development process or just learning how to code, GitHub is your home. Join the
          industry’s most powerful developer platform to build the technologies that change the world.
        </CTABanner.Description>

        <CTABanner.ButtonGroup>
          <Button href="/signup?source=form-home-signup">Sign up</Button>
          <Button href="/features/copilot">Try GitHub Copilot</Button>
        </CTABanner.ButtonGroup>
      </CTABanner>
    </Section>
  )
}

try{ Cta.displayName ||= 'Cta' } catch {}