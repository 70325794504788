import {Image} from '@primer/react-brand'

export interface MascotProps {
  name: 'copilot' | 'shield' | 'mona' | 'ducky'
}

const STATIC_FALLBACK: Record<MascotProps['name'], {url: string; alt: string}> = {
  copilot: {
    url: '/images/modules/site/home24/mascot-copilot.webp',
    alt: 'GitHub Copilot mascot',
  },
  shield: {
    url: '/images/modules/site/home24/mascot-shield.webp',
    alt: 'GitHub Shield mascot',
  },
  mona: {
    url: '/images/modules/site/home24/mascot-mona.webp',
    alt: 'GitHub Mona mascot',
  },
  ducky: {
    url: '/images/modules/site/home24/mascot-ducky.webp',
    alt: 'GitHub Ducky mascot',
  },
}

const Mascot: React.FC<MascotProps> = ({name}) => {
  // TODO: Implement WebGL scenes

  return (
    <div className="lp-Mascot">
      {/* WebGL */}
      <div className="lp-Mascot-webgl" />

      {/* Static fallback */}
      <Image className="lp-Mascot-static" src={STATIC_FALLBACK[name].url} alt={STATIC_FALLBACK[name].alt} />
    </div>
  )
}

export default Mascot

try{ Mascot.displayName ||= 'Mascot' } catch {}