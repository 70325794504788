import type React from 'react'
import {SectionIntro as PrimerSectionIntro} from '@primer/react-brand'

import type {MascotProps} from '../Mascot/Mascot'
import Mascot from '../Mascot/Mascot'
import {Spacer} from '../../_shared/Spacer'

export interface SectionIntroProps {
  mascot: MascotProps['name']
  title: string
  description?: string
  bottomSpacerSize?: 'default' | 'small'
}

const SectionIntro: React.FC<SectionIntroProps> = ({mascot, title, description, bottomSpacerSize = 'default'}) => {
  return (
    <div className="lp-SectionIntro">
      <Spacer size="96px" size1012="148px" />

      <Mascot name={mascot} />

      <PrimerSectionIntro className="sh-SectionIntro" align="center" fullWidth>
        <PrimerSectionIntro.Heading as="h2" size="3" className="lp-SectionIntro-heading">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{__html: title}} />
        </PrimerSectionIntro.Heading>

        {description && (
          <PrimerSectionIntro.Description>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{__html: description}} />
          </PrimerSectionIntro.Description>
        )}
      </PrimerSectionIntro>

      <Spacer
        size={`${bottomSpacerSize === 'small' ? 32 : 40}px`}
        size1012={`${bottomSpacerSize === 'small' ? 56 : 80}px`}
      />
    </div>
  )
}

export default SectionIntro

try{ SectionIntro.displayName ||= 'SectionIntro' } catch {}