import type {RefObject} from 'react'
import {useRef, useEffect} from 'react'
import Artwork from './intro-hero-webgl/artwork'
import Common from './intro-hero-webgl/common'
import Assets from './intro-hero-webgl/assets'

export function IntroHero({
  setCopyScrollOpacity,
  setCopyScrollScale,
}: {
  setCopyScrollOpacity: (o: number) => void
  setCopyScrollScale: (o: number) => void
}) {
  const wrapperRef = useRef<HTMLElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const animationFrameId = useRef(0)

  useEffect(() => {
    let isClosed = false
    const common = new Common()
    const assets = new Assets()
    const artwork = new Artwork(wrapperRef.current!, canvasRef.current!, common, assets)
    const resizeFunc = () => {
      const width = wrapperRef.current?.clientWidth || 0
      const windowWidth = document.body.clientWidth
      if (wrapperRef.current) {
        wrapperRef.current.style.left = `${Math.round(windowWidth * 0.5 - width * 0.5)}px`
      }
      artwork.resize()
    }
    resizeFunc()

    // eslint-disable-next-line github/prefer-observers
    window.addEventListener('resize', resizeFunc)
    const scrollFunc = () => {
      artwork.scroll()
    }

    // eslint-disable-next-line github/prefer-observers
    window.addEventListener('scroll', scrollFunc)

    assets.load(() => {
      if (!isClosed) {
        artwork.init()
      }
    })

    // let lastTimestamp = performance.now()
    // let fps = 0
    // let oldTime = new Date().getTime()

    const tick = () => {
      // const currentTime = new Date().getTime()
      // const currentTimestamp = performance.now()
      // const delta = currentTimestamp - lastTimestamp
      // fps = 1000 / delta
      // lastTimestamp = currentTimestamp

      // if (currentTime - oldTime > 1000) {
      //   oldTime = currentTime

      //   console.log(`FPS: ${fps.toFixed(2)}`)
      // }

      artwork.update()

      const scale = 1.0 - artwork.copyProgress.current * 0.1
      setCopyScrollScale(scale)

      const opacity = 1.0 - artwork.copyProgress.current
      setCopyScrollOpacity(opacity)

      animationFrameId.current = requestAnimationFrame(tick)
    }

    tick()
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current)
      }
      isClosed = true
      window.removeEventListener('resize', resizeFunc)
      window.removeEventListener('scroll', scrollFunc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="lp-IntroVisuals">
      <div className="lp-IntroVisuals-canvasWrapper" ref={wrapperRef as RefObject<HTMLDivElement>}>
        <canvas className="lp-IntroVisuals-canvas" ref={canvasRef as RefObject<HTMLCanvasElement>} />
      </div>
    </div>
  )
}

try{ IntroHero.displayName ||= 'IntroHero' } catch {}