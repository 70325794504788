import SectionIntro, {type SectionIntroProps} from '../SectionIntro/SectionIntro'
import SectionHero, {type SectionHeroProps} from '../SectionHero/SectionHero'
import {Accordion, Grid, Heading, Image, Link, Pillar, Testimonial, Text} from '@primer/react-brand'
import {useState} from 'react'

export interface Customer {
  logo: {
    url: string
    alt: string
    height: number
  }
  description: string
  link: {
    url: string
    label: string
  }
}

interface SectionTemplateProps {
  intro: SectionIntroProps
  hero: SectionHeroProps
  customers?: Customer[]
  testimonial?: {
    quote: string
    name: string
    position: string
  }
  content: {
    title: string
    description: string
    footnote?: string
    link: {
      url: string
      label: string
    }
  }
  details: {
    title: string
    description: string
    link: {
      url: string
      label: string
    }
    accordionItems: Array<{
      label: string
      body: string
    }>
    visual: {
      url: string
      alt: string
    }
  }
}

const SectionTemplate: React.FC<SectionTemplateProps> = ({intro, hero, content, customers, testimonial, details}) => {
  const [currentAccordionItem, setCurrentAccordionItem] = useState<number>(-1)

  return (
    <div className="lp-SectionTemplate">
      <div className="lp-SectionBlock">
        <div className="lp-SectionBlock-content">
          <SectionIntro {...intro} />
          <SectionHero {...hero} />
        </div>
      </div>

      <div className="lp-SectionBlock">
        <div className="lp-SectionBlock-content lp-SectionBlock-content--lines">
          <Grid className="lp-SectionTemplate-grid">
            <Grid.Column className="lp-SectionTemplate-grid-column" span={{xsmall: 12, medium: testimonial ? 6 : 7}}>
              <div className="lp-SectionTemplate-content">
                <Heading as="h3" size="6" weight="semibold" className="lp-SectionTemplate-content-title">
                  {content.title}
                  {/* TODO: Implement footnote logic globally */}
                  {content.footnote && <sup className="lp-SectionTemplate-content-note">{content.footnote}</sup>}
                  <span>{content.description}</span>
                </Heading>

                <div>
                  <Link href={content.link.url} variant="accent" className="lp-SectionTemplate-content-link">
                    <Text weight="normal">{content.link.label}</Text>
                  </Link>
                </div>
              </div>
            </Grid.Column>

            <Grid.Column className="lp-SectionTemplate-grid-column" span={{xsmall: 12, medium: testimonial ? 6 : 5}}>
              {testimonial && (
                <div className="lp-SectionTemplate-testimonial">
                  <Testimonial quoteMarkColor="green">
                    <Testimonial.Quote>{testimonial.quote}</Testimonial.Quote>
                    <Testimonial.Name position={testimonial.position}>{testimonial.name}</Testimonial.Name>
                  </Testimonial>
                </div>
              )}
              {customers &&
                customers.map((customer, index) => (
                  <div
                    // eslint-disable-next-line @eslint-react/no-array-index-key
                    key={`customer_${index}`}
                    className="lp-SectionTemplate-customer"
                  >
                    <Pillar>
                      <Pillar.Icon
                        className="lp-SectionTemplate-customer-logo"
                        icon={<Image src={customer.logo.url} alt={customer.logo.alt} height={customer.logo.height} />}
                      />
                      <Pillar.Description className="lp-SectionTemplate-customer-description">
                        <Text as="p" weight="light">
                          {customer.description}
                        </Text>
                      </Pillar.Description>
                      <Pillar.Link className="lp-SectionTemplate-customer-link" href={customer.link.url}>
                        <Text weight="normal">{customer.link.label}</Text>
                      </Pillar.Link>
                    </Pillar>
                  </div>
                ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>

      <div className="lp-SectionBlock">
        <div className="lp-SectionBlock-content">
          <Grid className="lp-SectionTemplate-grid">
            <Grid.Column
              className="lp-SectionTemplate-grid-column lp-SectionTemplate-grid-column--noDesktopBorder lp-SectionTemplate-details-visual"
              span={{xsmall: 12, medium: 6}}
            >
              <Image src={details.visual.url} alt={details.visual.alt} />
            </Grid.Column>

            <Grid.Column
              className="lp-SectionTemplate-grid-column lp-SectionTemplate-details-content"
              span={{xsmall: 12, medium: 6}}
            >
              <div className="lp-SectionTemplate-details-contentText">
                <Pillar>
                  <Pillar.Heading as="h3" size="6" className="lp-SectionTemplate-details-title">
                    <span>{details.title}</span>
                  </Pillar.Heading>
                  <Pillar.Description className="lp-SectionTemplate-details-description">
                    <Text as="p" weight="light">
                      {details.description}
                    </Text>
                  </Pillar.Description>
                  <Pillar.Link className="lp-SectionTemplate-details-link" href={details.link.url}>
                    <Text weight="normal">{details.link.label}</Text>
                  </Pillar.Link>
                </Pillar>
              </div>

              <div className="lp-SectionTemplate-details-accordion">
                {details.accordionItems.map((item, index) => (
                  <Accordion
                    // eslint-disable-next-line @eslint-react/no-array-index-key
                    key={`accordion_${index}`}
                    className="lp-SectionTemplate-details-accordionItem"
                    open={currentAccordionItem === index}
                    // TODO: Animation isn't smooth, look if can be improved without custom component
                    onClick={({target}) => {
                      const accordionItem = (target as HTMLElement).closest('.lp-SectionTemplate-details-accordionItem')
                      if (!accordionItem) return

                      const wasOpen = accordionItem.hasAttribute('open')
                      const newItemIndex = wasOpen ? -1 : index

                      setCurrentAccordionItem(newItemIndex)
                    }}
                  >
                    <Accordion.Heading>{item.label}</Accordion.Heading>
                    <Accordion.Content>
                      <Text className="lp-SectionTemplate-accordionContent" as="div">
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{__html: item.body}} />
                      </Text>
                    </Accordion.Content>
                  </Accordion>
                ))}
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default SectionTemplate

try{ SectionTemplate.displayName ||= 'SectionTemplate' } catch {}