import SectionTemplate from './components/SectionTemplate/SectionTemplate'

const COPY = {
  intro: {
    title: 'Work together, achieve more',
    description:
      'Collaborate with your teams, use project management tools that sync with your projects, and code from anywhere, all on a single, integrated platform.',
  },
  hero: {
    url: '/images/modules/site/home24/collaboration/hero.webp',
    // TODO: Update once known
    alt: '',
  },
  content: {
    title: 'Your workflows, your way.',
    description: ' Plan effectively with an adaptable spreadsheet that syncs with your work.',
    link: {
      url: '/features/issues',
      label: 'Jump into GitHub Projects',
    },
  },
  testimonial: {
    quote:
      'It helps us onboard new software engineers and get them productive right away. We have all our source code, issues, and pull requests in one place. ... GitHub is a complete platform that frees us from menial tasks and enables us to do our best work.',
    name: 'Fabian Faulhaber',
    position: 'Application Manager at Mercedes-Benz',
  },
  details: {
    title: 'Automate any workflow',
    description: 'Optimize your process with simple and secure CI/CD.',
    link: {
      url: '/features/issues',
      label: 'Explore GitHub Actions',
    },
    accordionItems: [
      {
        label: 'Keep track of your tasks',
        // TODO: Update once known
        body: 'Body copy',
      },
      {
        label: 'Create issues and manage projects with tools that adapt to your code',
        // TODO: Update once known
        body: 'Body copy',
      },
      {
        label: 'Explore GitHub Issues',
        // TODO: Update once known
        body: 'Body copy',
      },
    ],
    visual: {
      url: '/images/modules/site/home24/collaboration/details-visual.webp',
      // TODO: Update once known
      alt: '',
    },
  },
}

export default function Collaboration() {
  return (
    <section id="collaboration" className="lp-Section">
      <SectionTemplate
        intro={{
          mascot: 'mona',
          title: COPY.intro.title,
          description: COPY.intro.description,
        }}
        hero={{visual: COPY.hero}}
        content={COPY.content}
        testimonial={COPY.testimonial}
        details={COPY.details}
      />
    </section>
  )
}

try{ Collaboration.displayName ||= 'Collaboration' } catch {}