import type React from 'react'
import PlayButton from '../PlayButton/PlayButton'
import {Link, Heading, Image} from '@primer/react-brand'

export interface SectionHeroProps {
  visual: {
    url: string
    alt: string
  }
  isVideo?: boolean
  text?: {
    title: string
    description: string
    link: {
      url: string
      label: string
    }
  }
}

const SectionHero: React.FC<SectionHeroProps> = ({visual, isVideo, text}) => {
  return (
    <div className="lp-SectionHero">
      {/* TODO: Adjust background */}
      <div className="lp-SectionHero-background" />

      {isVideo ? (
        <div className="lp-SectionHero-playButton">
          {/* TODO: Implement pause logic */}
          <PlayButton onTimeout={() => null} />
        </div>
      ) : null}

      <div className={`lp-SectionHero-content ${text ? 'lp-SectionHero-content--hasText' : ''}`}>
        {text ? (
          <div className="lp-SectionHero-text">
            <Heading as="h3" size="6" weight="semibold" className="lp-SectionHero-text-title">
              {text.title}
              <span>{text.description}</span>
            </Heading>

            <div>
              <Link href={text.link.url} variant="accent" className="lp-SectionHero-text-link">
                {text.link.label}
              </Link>
            </div>
          </div>
        ) : null}

        <div className="lp-SectionHero-visual">
          <div className="lp-SectionHero-visual-frame">
            {isVideo ? (
              <video className="lp-SectionHero-visual-video" autoPlay muted loop playsInline>
                <source src={visual.url} type="video/mp4" />
              </video>
            ) : (
              <Image src={visual.url} alt={visual.alt} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionHero

try{ SectionHero.displayName ||= 'SectionHero' } catch {}