import {Hero, LogoSuite, Image} from '@primer/react-brand'
import {Spacer} from './_shared'
import {LOGOS} from './IntroLogos.data'
import {IntroHero} from './IntroHero'
import {useRef, useCallback} from 'react'

export default function Intro() {
  const heroRef = useRef<HTMLDivElement | null>(null)

  const setCopyScrollOpacity = useCallback((copyScrollOpacity: number) => {
    if (heroRef.current) {
      heroRef.current.style.opacity = `${copyScrollOpacity}` // Set the desired opacity value
    }
  }, [])

  const setCopyScrollScale = useCallback((copyScrollScale: number) => {
    if (heroRef.current) {
      heroRef.current.style.transform = `scale(${copyScrollScale})` // Set the desired scale value
    }
  }, [])

  return (
    <section id="hero">
      <div className="lp-Intro">
        {/* Header + Subnav = 136px */}
        <Spacer size="136px" />
        <Spacer size="48px" size1012="96px" />

        <IntroHero setCopyScrollOpacity={setCopyScrollOpacity} setCopyScrollScale={setCopyScrollScale} />

        {/* Hero */}
        <Hero ref={heroRef} className="lp-IntroHero" data-hpc align="center">
          <Hero.Heading size="2">Build and ship software on a single, collaborative platform</Hero.Heading>

          <Hero.Description size="300">
            Join the industry’s leading AI-powered developer platform where millions of developers, thousands of
            businesses, and the world’s largest open source community create the software that shapes our world.
          </Hero.Description>

          <Hero.PrimaryAction href="/signup?source=form-home-signup">Sign up</Hero.PrimaryAction>
          <Hero.SecondaryAction className="lp-IntroHero-secondaryAction" href="/features/copilot">
            Try GitHub Copilot
          </Hero.SecondaryAction>
        </Hero>

        <Spacer size="365px" />
        <Spacer size="120px" size1012="300px" />

        {/* Demos */}
        <div className="lp-IntroDemos">
          <Image className="lp-IntroDemos-image" src="/images/modules/site/home24/intro/demo-1.webp" alt="TODO" />
        </div>
      </div>

      <Spacer size="48px" size1012="96px" />

      {/* Logos */}
      <LogoSuite>
        <LogoSuite.Heading visuallyHidden>GitHub is used by</LogoSuite.Heading>
        <LogoSuite.Logobar marquee marqueeSpeed="slow">
          {LOGOS.map((logo, index) => (
            // eslint-disable-next-line @eslint-react/no-array-index-key
            <Image key={index} src={logo.image} alt={logo.alt} style={{height: logo.height}} />
          ))}
        </LogoSuite.Logobar>
      </LogoSuite>
    </section>
  )
}

try{ Intro.displayName ||= 'Intro' } catch {}