import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {Grid, OrderedList, SubNav, Text, ThemeProvider} from '@primer/react-brand'
import {Spacer} from '../features/components/Spacer'

import {Image} from './../../components/Image/Image'

import {CallToAction} from './CallToAction'
import {PricingTable} from './PricingTable'
import {PricingCards} from './PricingCards'
import {analyticsEvent, withCohortFunnelParams} from '../../lib/analytics'
import {SUBNAV_LINKS} from './SubNav.data'
import {useEffect, useState} from 'react'

export function CopilotPlansIndex() {
  const copilotSignupPathPayload = useRoutePayload<{copilotSignupPath: string}>()
  const {copilotForBusinessSignupPath} = useRoutePayload<{copilotForBusinessSignupPath: string}>()
  const {copilotContactSalesPath} = useRoutePayload<{copilotContactSalesPath: string}>()
  const {loggedIn} = useRoutePayload<{loggedIn: boolean}>()

  const [copilotSignupPath, setCopilotSignupPath] = useState<string>(copilotSignupPathPayload.copilotSignupPath || '')

  useEffect(() => {
    setCopilotSignupPath(
      withCohortFunnelParams(copilotSignupPath, {
        funnel: 'copilot',
        entry: 'copilot_plans',
        product: 'cfi',
        loggedIn,
      }),
    )
  }, [copilotSignupPath, loggedIn])

  return (
    <ThemeProvider colorMode="dark" className="lp-Copilot">
      {/* Header 72px */}
      <Spacer size="66px" size768="72px" />
      <SubNav className="lp-Hero-subnav">
        <SubNav.Heading
          href={SUBNAV_LINKS.logo.url}
          className="d-block mr-3 mr-lg-4 position-relative lp-Hero-subnav-heading"
        >
          {SUBNAV_LINKS.logo.label}
          <Text className="lp-Hero-subnav-separator" size="300" weight="semibold" aria-hidden="true">
            /
          </Text>
        </SubNav.Heading>
        {SUBNAV_LINKS.items.map((item, index) => (
          <SubNav.Link
            // eslint-disable-next-line @eslint-react/no-array-index-key
            key={`subnav_${index}`}
            href={!index ? item.url : '#'}
            className={!index ? 'selected' : ''}
            aria-current={index ? 'page' : undefined}
          >
            {item.label}
          </SubNav.Link>
        ))}
      </SubNav>

      <Spacer size="52px" size768="0px" />

      <section id="pricing" className="lp-Section lp-Section--pricing">
        <Image
          as="picture"
          src="/images/modules/site/copilot/pricing-gradient.jpg"
          className="position-absolute top-0 left-0 width-100 height-100"
          sources={[
            {
              srcset: '/images/modules/site/copilot/pricing-gradient-sm.jpg',
              media: '(max-width: 767px)',
            },
            {
              srcset: '/images/modules/site/copilot/pricing-gradient.jpg',
              media: '(min-width: 768px) and (max-width: 1279px)',
            },
            {
              srcset: '/images/modules/site/copilot/pricing-gradient-lg.jpg',
              media: '(min-width: 1280px)',
            },
          ]}
          alt=""
        />
        <PricingCards
          copilotSignupPath={copilotSignupPath}
          copilotForBusinessSignupPath={copilotForBusinessSignupPath}
          copilotContactSalesPath={copilotContactSalesPath}
        />
      </section>

      <section className="lp-Section pt-0">
        <PricingTable
          copilotSignupPath={copilotSignupPath}
          copilotForBusinessSignupPath={copilotForBusinessSignupPath}
          copilotContactSalesPath={copilotContactSalesPath}
        />

        <div className="mt-8">
          <CallToAction copilotContactSalesPath={copilotContactSalesPath} />
        </div>
      </section>

      <section id="footnotes" className="lp-Section" style={{paddingTop: '0'}}>
        <Grid className="lp-Grid--noRowGap">
          <Grid.Column span={12}>
            <OrderedList>
              <OrderedList.Item className="lp-Footnotes-item">
                <a
                  className="lp-Link--inline"
                  href="https://docs.github.com/en/enterprise-cloud@latest/authentication/authenticating-with-saml-single-sign-on/about-authentication-with-saml-single-sign-on"
                  {...analyticsEvent({
                    action: 'saml_sso',
                    tag: 'link',
                    context: 'footnote',
                    location: 'features_table',
                  })}
                >
                  Authentication with SAML single sign-on (SSO)
                </a>{' '}
                available for organizations using GitHub Enterprise Cloud.
              </OrderedList.Item>
              <OrderedList.Item className="lp-Footnotes-item">
                Feature in public beta for Copilot Individual and Business plans. Requires use of repositories, issues,
                discussions, Actions, and other features of GitHub.
              </OrderedList.Item>
            </OrderedList>
          </Grid.Column>
        </Grid>
      </section>
    </ThemeProvider>
  )
}

try{ CopilotPlansIndex.displayName ||= 'CopilotPlansIndex' } catch {}