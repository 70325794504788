type MarketingAnalyticsEventAttrs = {
  action: string
  tag: string
  context: string
  location: string
}

// Adds "data-analytics-event" attribute to elements for tracking
export function analyticsEvent({action, tag, context, location}: MarketingAnalyticsEventAttrs) {
  return {
    'data-analytics-event': JSON.stringify({
      action,
      tag,
      context,
      location,
      label: `${action}_${tag}_${context}_${location}`,
    }),
  }
}

export function withCohortFunnelParams(
  path: string,
  {funnel, loggedIn, ...options}: {funnel: string; loggedIn: boolean; entry?: string; product?: string},
): string {
  const newQueryParams = new URLSearchParams()

  // Extract any existing params from the passed in path
  const [pathname, queryString] = path.split('?')
  const pathParams = new URLSearchParams(queryString)

  // Add path params to the new query params
  for (const [key, value] of pathParams.entries()) {
    newQueryParams.append(key, value)
  }

  // If the path params have a `return_to` param, we need to add our cohort funnel params to that URL instead
  if (pathParams.has('return_to')) {
    const returnTo = pathParams.get('return_to')
    // decode the return_to param to get the path and query params
    const decodedReturnTo = decodeURIComponent(returnTo || '')

    const returnToWithCohortFunnel = withCohortFunnelParams(decodedReturnTo, {funnel, loggedIn, ...options})

    newQueryParams.set('return_to', returnToWithCohortFunnel)
  }

  // Get the query params from the current window location
  const existingURLParams = new URLSearchParams(window.location.search)

  // Extract the cft param from the existing URL params
  const existingCohortFunnel = existingURLParams.get('cft') || ''

  // Build the individual parts of the cft param
  let [cohortFunnel, entry, product] = existingCohortFunnel.split('.')

  // Build the cohort funnel parts
  if (!cohortFunnel) {
    cohortFunnel = funnel
    if (loggedIn) {
      cohortFunnel += '_li'
    } else {
      cohortFunnel += '_lo'
    }
    if (isExistingUser()) {
      cohortFunnel += `_eu`
    } else {
      cohortFunnel += `_nu`
    }
  }

  // Add the entry and product parts if they don't already exist
  if (!entry) {
    entry = options.entry
  }

  if (!product) {
    product = options.product
  }

  // Build the value of the cft param by joining the parts with a dot
  const cohortFunnelParts = [cohortFunnel, entry, product].filter(Boolean).join('.')

  // Set the cft param to the new query params
  newQueryParams.set('cft', cohortFunnelParts)

  // Return the new path with the updated query params
  return `${pathname}?${newQueryParams.toString()}`
}

function isExistingUser() {
  // Check if the dotcom_user cookie is set
  return document.cookie.includes('dotcom_user')
}
