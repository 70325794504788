import {Grid, Heading, Image, Link, Text} from '@primer/react-brand'
import {Spacer} from './_shared'
import SectionIntro from './components/SectionIntro/SectionIntro'
import {Fragment} from 'react'

const COPY = {
  intro: {
    title:
      'From startups to enterprises, <span>GitHub scales <br class="lp-breakText" /> with teams of any size in any industry.</span>',
  },
  content: {
    stories: [
      {
        logo: {
          url: '/images/modules/site/home24/customer-stories/duolingo.svg',
          // TODO: Update once known
          alt: '',
          height: 50,
        },
        title: 'Technology',
        description: 'Duolingo boosts developer speed by 25% with Copilot',
        link: {
          url: '/customer-stories/duolingo',
          label: 'Explore customer stories',
        },
      },
      {
        logo: {
          url: '/images/modules/site/home24/customer-stories/mercedes.svg',
          // TODO: Update once known
          alt: '',
          height: 42,
        },
        title: 'Automotive',
        description: 'Mercedez-Benz standardizes source code and automates onboarding',
        link: {
          url: '/customer-stories/mercedes-benz',
          label: 'Explore customer stories',
        },
      },
      {
        logo: {
          url: '/images/modules/site/home24/customer-stories/mercado-libre.svg',
          // TODO: Update once known
          alt: '',
          height: 37,
        },
        title: 'Financial services',
        description: 'Mercado Libre cuts coding time by 50%',
        link: {
          url: '/customer-stories/mercado-libre',
          label: 'Explore customer stories',
        },
      },
    ],
  },
  links: [
    {
      url: '/customer-stories',
      label: 'Explore customer stories',
    },
    {
      url: '/solutions',
      label: 'Explore all solutions',
    },
  ],
}

export default function CustomerStories() {
  return (
    <section id="customer-stories" className="lp-Section">
      <div className="lp-SectionBlock">
        <div className="lp-SectionBlock-content">
          <SectionIntro mascot="ducky" title={COPY.intro.title} bottomSpacerSize="small" />
        </div>
      </div>

      <div className="lp-SectionBlock">
        <div className="lp-SectionBlock-content lp-SectionBlock-content--lines">
          {/*
            TODO: Add filter toggle
          */}
          <Grid className="lp-CustomerStories-grid">
            {COPY.content.stories.map((story, index) => (
              <Grid.Column
                // eslint-disable-next-line @eslint-react/no-array-index-key
                key={`story_${index}`}
                className="lp-CustomerStories-gridColumn"
                span={{xsmall: 12, medium: 6, large: 4}}
              >
                <Image
                  className="lp-CustomerStories-logo"
                  src={story.logo.url}
                  alt={story.logo.alt}
                  height={story.logo.height}
                />
                <div>
                  <Heading className="lp-CustomerStories-heading" size="subhead-medium">
                    {story.title}
                  </Heading>
                  <Text className="lp-CustomerStories-description">{story.description}</Text>
                </div>
              </Grid.Column>
            ))}
          </Grid>
        </div>
      </div>

      <div className="lp-SectionBlock lp-SectionBlock--noBorder">
        <div className="lp-SectionBlock-content">
          <Spacer size="32px" size1012="48px" />

          {/* Links */}
          <div className="lp-CustomerStories-links">
            {COPY.links.map((link, index) => (
              // eslint-disable-next-line @eslint-react/no-array-index-key
              <Fragment key={`link_${index}`}>
                <Link variant="accent" className="lp-CustomerStories-link" href={link.url}>
                  <Text weight="normal">{link.label}</Text>
                </Link>
                {index < COPY.links.length - 1 && <hr className="lp-CustomerStories-divider" />}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

try{ CustomerStories.displayName ||= 'CustomerStories' } catch {}