import SectionTemplate from './components/SectionTemplate/SectionTemplate'

const COPY = {
  intro: {
    title: 'Accelerate performance',
    description:
      'With Copilot embedded throughout the platform, you can simplify your toolchain, automate tasks, and improve the developer experience.',
  },
  hero: {
    url: '/images/modules/site/home24/automation/hero.webp',
    // TODO: Update once known
    alt: '',
  },
  content: {
    title: 'Work 55% faster.',
    description: ' Increase performance with AI-powered coding assistance, including code completion, chat, and more.',
    footnote: '1',
    link: {
      url: '/features/copilot',
      label: 'Explore GitHub Copilot',
    },
  },
  customers: [
    {
      logo: {
        url: '/images/modules/site/home24/automation/logo-duolingo.svg',
        // TODO: Update once known
        alt: '',
        height: 32,
      },
      description: 'Discover how Duolingo increased developer speed with Copilot',
      link: {
        url: '/customer-stories/duolingo',
        label: 'Read customer story',
      },
    },
    {
      logo: {
        url: '/images/modules/site/home24/automation/logo-gartner.svg',
        // TODO: Update once known
        alt: '',
        height: 24,
      },
      description: '2024 Gartner® Magic Quadrant™ for AI Code Assistants',
      link: {
        url: 'https://www.gartner.com/doc/reprints?id=1-2IKO4MPE&ct=240819&st=sb',
        label: 'Read the report',
      },
    },
  ],
  details: {
    title: 'Automate any workflow',
    description: 'Optimize your process with simple and secure CI/CD.',
    link: {
      url: '/features/actions',
      label: 'Explore GitHub Actions',
    },
    accordionItems: [
      {
        label: 'Get up and running in seconds',
        // TODO: Update once known
        body: 'Body copy',
      },
      {
        label: 'Build on the go',
        // TODO: Update once known
        body: 'Body copy',
      },
      {
        label: 'Integrate the tools you love',
        // TODO: Update once known
        body: 'Body copy',
      },
    ],
    visual: {
      url: '/images/modules/site/home24/automation/details-visual.webp',
      // TODO: Update once known
      alt: '',
    },
  },
}

export default function Automation() {
  return (
    <section id="automation" className="lp-Section">
      <SectionTemplate
        intro={{
          mascot: 'copilot',
          title: COPY.intro.title,
          description: COPY.intro.description,
        }}
        hero={{visual: COPY.hero}}
        content={COPY.content}
        customers={COPY.customers}
        details={COPY.details}
      />
    </section>
  )
}

try{ Automation.displayName ||= 'Automation' } catch {}