import {Grid, Pillar, Image, Statistic, Text} from '@primer/react-brand'
import {Spacer} from './_shared'
import SectionHero from './components/SectionHero/SectionHero'
import SectionIntro from './components/SectionIntro/SectionIntro'

const COPY = {
  intro: {
    title: 'Built-in application security <br class="lp-breakText" /> where found means fixed',
    description: 'Use AI to find and fix vulnerabilities—freeing your teams to ship more secure software faster.',
  },
  hero: {
    title: 'Apply fixes in seconds.',
    description: ' Spend less time fixing vulnerabilities and more time building features with Copilot Autofix',
    link: {
      url: '/enterprise/advanced-security',
      label: 'Explore GitHub Advanced Security',
    },
    aria: {
      // TODO: Update once known
      visual: '',
    },
  },
  topics: [
    {
      title: 'Security campaigns',
      description:
        ' Leverage AI-assisted security campaigns to reduce application vulnerabilities and zero-day attacks.',
      link: {
        url: '/enterprise/advanced-security',
        label: 'Discover security campaigns',
      },
      visual: {
        url: '/images/modules/site/home24/security/pillar-1.webp',
        // TODO: Update once known
        alt: '',
      },
    },
    {
      title: 'Dependencies you can depend on.',
      description: ' Confidently update vulnerable dependences with supported fixes for breaking changes.',
      link: {
        url: '/features/security/software-supply-chain',
        label: 'Learn about Dependabot',
      },
      visual: {
        url: '/images/modules/site/home24/security/pillar-2.webp',
        // TODO: Update once known
        alt: '',
      },
    },
    {
      title: 'Your secrets, your business: protected. ',
      description: ' Detect, prevent, and remediate leaked secrets across your organization.',
      link: {
        url: '/features/security/code',
        label: 'Read about secret scanning',
      },
      visual: {
        url: '/images/modules/site/home24/security/pillar-3.webp',
        // TODO: Update once known
        alt: '',
      },
    },
  ],
  statistics: [
    {
      heading: '7x faster',
      description: 'vulnerability fixes with GitHub',
      footnote: '2',
    },
    {
      heading: '90% coverage',
      description: 'of alert types in all supported languages with Copilot Autofix',
      footnote: '3',
    },
  ],
}

export default function Security() {
  return (
    <section id="security" className="lp-Section">
      <div className="lp-SectionBlock">
        <div className="lp-SectionBlock-content">
          <SectionIntro mascot="shield" title={COPY.intro.title} description={COPY.intro.description} />

          <Spacer size="40px" size1012="80px" />

          <SectionHero
            visual={{
              url: '/images/modules/site/home24/security/hero.webp',
              alt: COPY.hero.aria.visual,
            }}
            text={{
              title: COPY.hero.title,
              description: COPY.hero.description,
              link: {
                url: COPY.hero.link.url,
                label: COPY.hero.link.label,
              },
            }}
          />
        </div>
      </div>

      <div className="lp-SectionBlock">
        <div className="lp-SectionBlock-content lp-SectionBlock-content--lines">
          <Grid className="Security-grid Security-grid--hasPillars">
            {COPY.topics.map((topic, index) => (
              <Grid.Column
                className="Security-gridColumn"
                span={{xsmall: 12, medium: 6, large: 4}}
                // eslint-disable-next-line @eslint-react/no-array-index-key
                key={`topic_${index}`}
              >
                <Pillar>
                  <Pillar.Description className="Security-pillarDescription">
                    <span>{topic.title}</span>
                    {topic.description}
                  </Pillar.Description>
                  <Pillar.Link href={topic.link.url}>
                    <Text weight="normal">{topic.link.label}</Text>
                  </Pillar.Link>
                </Pillar>

                <Image className="Security-gridImage" src={topic.visual.url} alt={topic.visual.alt} />
              </Grid.Column>
            ))}
          </Grid>

          <Grid className="Security-grid">
            {COPY.statistics.map((statistic, index) => (
              <Grid.Column
                className="Security-gridColumn"
                span={{xsmall: 12, medium: 6}}
                // eslint-disable-next-line @eslint-react/no-array-index-key
                key={`statistic_${index}`}
              >
                <Statistic>
                  <Statistic.Heading className="Security-statisticHeading" size="4">
                    {statistic.heading}
                  </Statistic.Heading>
                  <Statistic.Description className="Security-statisticDescription">
                    {statistic.description}
                    {/* TODO: Implement footnote logic globally */}
                    {statistic.footnote && <sup>{statistic.footnote}</sup>}
                  </Statistic.Description>
                </Statistic>
              </Grid.Column>
            ))}
          </Grid>
        </div>
      </div>
    </section>
  )
}

try{ Security.displayName ||= 'Security' } catch {}